import React, { useState } from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ToggleButton = ({ initialState = false }) => {
  const [isOn, setIsOn] = useState(initialState);

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <ToggleWrapper onClick={toggleSwitch}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d={
            isOn
              ? "M10 6C7.34784 6 4.8043 7.05357 2.92893 8.92893C1.05357 10.8043 0 13.3478 0 16C0 18.6522 1.05357 21.1957 2.92893 23.0711C4.8043 24.9464 7.34784 26 10 26H22C24.6522 26 27.1957 24.9464 29.0711 23.0711C30.9464 21.1957 32 18.6522 32 16C32 13.3478 30.9464 10.8043 29.0711 8.92893C27.1957 7.05357 24.6522 6 22 6H10ZM22 24C19.8783 24 17.8434 23.1571 16.3431 21.6569C14.8429 20.1566 14 18.1217 14 16C14 13.8783 14.8429 11.8434 16.3431 10.3431C17.8434 8.84285 19.8783 8 22 8C24.1217 8 26.1566 8.84285 27.6569 10.3431C29.1571 11.8434 30 13.8783 30 16C30 18.1217 29.1571 20.1566 27.6569 21.6569C26.1566 23.1571 24.1217 24 22 24Z"
              : "M22 6C24.6522 6 27.1957 7.05357 29.0711 8.92893C30.9464 10.8043 32 13.3478 32 16C32 18.6522 30.9464 21.1957 29.0711 23.0711C27.1957 24.9464 24.6522 26 22 26H10C7.34784 26 4.8043 24.9464 2.92893 23.0711C1.05357 21.1957 0 18.6522 0 16C0 13.3478 1.05357 10.8043 2.92893 8.92893C4.8043 7.05357 7.34784 6 10 6H22ZM10 24C12.1217 24 14.1566 23.1571 15.6569 21.6569C17.1571 20.1566 18 18.1217 18 16C18 13.8783 17.1571 11.8434 15.6569 10.3431C14.1566 8.84285 12.1217 8 10 8C7.87827 8 5.84344 8.84285 4.34315 10.3431C2.84285 11.8434 2 13.8783 2 16C2 18.1217 2.84285 20.1566 4.34315 21.6569C5.84344 23.1571 7.87827 24 10 24Z"
          }
          fill={isOn ? "#3BE780" : "#727272"}
        />
      </svg>
    </ToggleWrapper>
  );
};

export default ToggleButton;
